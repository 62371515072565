<template>
	<div id="userList">
		<div class="el-content mb12">
			<a-space>
				<a-input-search 
					v-model:value="name" 
					style="width: 400px" 
					placeholder="姓名" 
					enter-button 
					@search="getUserList(1, info.limit)" 
				/>
				<a-button type="primary" v-has="{ action: 'sass_agency_add' }" @click="addAgencyShow">
					<i class="ri-add-line ri-top"></i>
					添加代理商
				</a-button>
			</a-space>
		</div>
		<div class="el-content">
			<div class="kd-pacel">列表</div>
			<a-table :pagination="false" row-key="user_id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'user_id'},
				{title:'手机号',dataIndex:'mobile'},
				{title:'用户名',dataIndex:'name'},
				{title:'可创建农场(个)',dataIndex:'company.limit'},
				{ title: '续费折扣', dataIndex: 'is_renewal_discount' ,slots:{customRender:'is_renewal_discount'}},
				{title:'状态',dataIndex:'status', slots: { customRender: 'status' }},
				{title:'创建时间',dataIndex:'create_time'},
				{title:'最后登录时间',dataIndex:'login_time'},
				{title:'最后登录IP',dataIndex:'last_login_ip'},
				{title:'操作',dataIndex:'action', slots: { customRender: 'action' }},
			]" >
				<template #status="{ record }">
					<a-tag :color="record.status == 1 ? '#00CC66':'#FF6633'">{{ record.status ? "正常":"已禁用" }}</a-tag>
				</template>
				<template #is_renewal_discount="{ record }">
					<a-tooltip title="点击修改续费折扣状态">
						<a-tag 
							class="pt"
							@click="changeRenewDiscount(record)" 
							:color="record.is_renewal_discount ? '#00CC66' : '#999'"
						>
							{{ record.is_renewal_discount ? '有' : '无' }}
						</a-tag>
					</a-tooltip>
				</template>
				<template #action="{ record }">
					<router-link :to="{path:'/admin/agency/agency_info',query:{user_id:record.user_id}}">
						<a-button v-has="{action:'/admin/agency/agency_info'}" type="link" size="small">详情</a-button>
					</router-link>
					<a-button v-has="{action:'sass_agency_stop'}" type="link" @click="deletUser(record)" size="small">
						{{record.status ==1 ? '禁用':'启用'}}
					</a-button>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination 
					show-size-changer 
					v-model:current="info.page" 
					v-model:pageSize="info.limit" 
					:total="info.count" 
					@showSizeChange="(p,e)=>{getUserList(info.page,e)}"
					@change="(e)=>getUserList(e,info.limit)"
				/>
			</div>
		</div>
		<a-modal v-model:visible="agencyState.show" 
			title="添加编辑代理商" 
			width="700px"  
			@ok="saveAgency" 
			@cancel="agencyState.show = false"
		>
			<a-form :label-col="{span:4}" :wrapper-col="{span:18}">
				<a-form-item label="昵称">
					<a-input v-model:value="agencyState.form.name"></a-input>
				</a-form-item>
				<a-form-item label="手机号">
					<a-input v-model:value="agencyState.form.mobile"></a-input>
				</a-form-item>
				<a-form-item label="密码" v-if="!agencyState.form.user_id">
					<a-input v-model:value="agencyState.form.password"></a-input>
				</a-form-item>
				<a-form-item label="地址">
					<a-input v-model:value="agencyState.form.address"></a-input>
				</a-form-item>
				<a-form-item label="续费折扣">
					<kd-switch 
                        :value="agencyState.form.is_renewal_discount" 
                        @change="e=>agencyState.form.is_renewal_discount = e"
                    >
                    </kd-switch>
				</a-form-item>
				<a-form-item label="永久授权">
					<a-radio-group v-model:value="agencyState.form.is_permanent">
						<a-radio :value="1">开启</a-radio>
						<a-radio :value="0">关闭</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="授权到期时间">
					<a-date-picker v-model:value="agencyState.form.validity_time" format="YYYY-MM-DD"/>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRaw, toRefs } from 'vue';
import saasUserModel from '@/api/saas/user'
export default {
	setup(){
		const state = reactive({
			info: {
				list: [],
				page: 1,
				count: 0,
				limit: 10
			},
			name: '',
		})
		getUserList(1,state.info.limit)

        let agencyState = useEditAgency({state,getUserList})

		function getUserList(page,limit){
			saasUserModel.getAgentUser(page,limit,{name:state.name},res=>state.info = {limit,...res})
		}

		const deletUser = row =>saasUserModel.deleteUser(row.user_id,row.status,()=>{
			getUserList(state.info.page,state.info.limit)
		})

		const changeRenewDiscount =(data)=>{
			let param = {
				pic_url: data.pic_url,
				name: data.name,
				mobile: data.mobile,
				email: data.email,
				user_id: data.user_id,
				address: data.address,
				type: 1,
				rank: 2,
				scene: 'edit',
				is_renewal_discount:data.is_renewal_discount ==1 ? 0 : 1
			}
			saasUserModel.addOrEditAgentUser(param,()=>getUserList(state.info.page,state.info.limit))
		}

		return{
			...toRefs(state),
			getUserList,
			deletUser,
			changeRenewDiscount,
            ...agencyState
		}
	}
}

//添加代理商
function useEditAgency(obj){
    let agencyState =reactive({
        show:false,
        form:null
    }) 

    function addAgencyShow(){
        agencyState.form = {
            name: '',
            mobile: '',
            password: '',
            is_permanent: 0,
            validity_time: '',
            address: '',
            is_renewal_discount:0
        }
        agencyState.show = true
    }

    function saveAgency(){
        saasUserModel.addOrEditAgentUser( toRaw(agencyState.form),()=>{
			obj.getUserList(obj.state.info.page,obj.state.info.limit)
			agencyState.show = false
		})
    }

    return{ agencyState,addAgencyShow ,saveAgency}
}


</script>

<style lang="scss"></style>
